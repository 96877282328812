import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Arrow from '../../../../static/svg/arrow.svg'

const useStyles = makeStyles(theme => ({
    container: {
        width: 180,
        marginTop: 15,
        '&:first-child': {
            marginTop: 0,
        },
        '@media (max-width:1024px)': {
            width: 'fit-content',
            marginTop: '0.94vw',
        },
        '@media (max-width:767px)': {
            marginTop: 15,
        },
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            fontSize: 14,
            '@media (max-width:1024px)': {
                fontSize: '0.88vw',
            },
            '@media (max-width:767px)': {
                fontSize: 12,
            },
            '@media (max-width:344px)': {
                fontSize: 11,
            },
        },
    },
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: 14,
        marginRight: props =>
            props.hover ?
                -4.2
            :
                0,
        marginTop: props =>
            props.hover ?
                -4.2
            :
                0,
        marginLeft: props =>
            props.hover ?
                10.2
            :
                6,
        transition: '.3s all ease',
        transform: props =>
            props.hover ?
                'scale(1.3)'
            :
                'scale(1)',
        '@media (min-width:768px) and (max-width:1024px)': {
            width: '0.88vw',
        },
    },
}))

export default function ContactLink({ name, link }) {

    const [hover, setHover] = React.useState(false)

    const classes = useStyles({ hover })

    return (
        <div className={classes.container}>
            <a href={`${link}`} target="_blank" rel="noopener noreferrer" className={classes.link} onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
                <div className={classes.root}>
                    <Typography variant="body1">
                        {name}
                    </Typography>
                    <div className={classes.icon}>
                        <Arrow />
                    </div>
                </div>
            </a>
        </div>
    )
}