import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        width: 50,
        marginRight: 10,
        transition: '.3s all ease',
        '-webkit-transition': '.3s all ease',
        '&:hover': {
            transform: 'scale(1.1)',
            '-webkit-transform': 'scale(1.1)',
        },
        '&:last-child': {
            marginRight: 0,
        },
        '@media (min-width:768px) and (max-width:1024px)': {
            width: '3.13vw',
            marginRight: '0.63vw',
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    img: {
        width: '100%',
        height: 'auto',
    },
}))

export default function SocialLink({ social, link, alt }) {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <a href={`${link}`} target="_blank" rel="noopener noreferrer">
                <img src={`${social}`} alt={`${alt}`} width="50" height="45" className={classes.img} style={{ display: 'block', }} />
            </a>
        </div>
    )
}