import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { elementScrollIntoView } from "seamless-scroll-polyfill"

const useStyles = makeStyles(theme => ({
  root: {
    width: "fit-content",
    borderRadius: 1000,
    padding: props =>
      props.active ? "7px 17px 7px 17px" : "7px 30px 7px 17px",
    boxShadow: props =>
      props.active ? "none" : "inset 0px 0px 0px 1px rgba(255, 255, 255, 0.5)",
    background: props => (props.active ? theme.palette.primary.accent : ""),
    transition: ".5s all ease",

    "& .MuiTypography-root": {
      color: props => (props.active ? theme.palette.background.main : ""),
    },

    "&:hover": {
      padding: props =>
        props.active ? "7px 33px 7px 33px" : "7px 50px 7px 17px",
      background: theme.palette.primary.accent,
      boxShadow: "none",
      border: "none",

      "& .MuiTypography-root": {
        transition: ".5s all ease",
        color: theme.palette.background.main,
      },
    },

    "@media (max-width:1440px)": {
      "& .MuiTypography-root": {
        fontSize: "0.97vw",
      },
    },

    "@media (max-width:1024px)": {
      padding: props =>
        props.active
          ? "0.44vw 1.06vw 0.44vw 1.06vw"
          : "0.44vw 1.88vw 0.44vw 1.06vw",

      "& .MuiTypography-root": {
        fontSize: "1.06vw",
      },

      "&:hover": {
        padding: props =>
          props.active
            ? "0.44vw 2.09vw 0.44vw 2.09vw"
            : "0.44vw 3.13vw 0.44vw 1.06vw",
      },
    },

    "@media (max-width:767px)": {
      padding: "5px 40px 5px 12px",

      "& .MuiTypography-root": {
        fontSize: 13,
      },

      "&:hover": {
        padding: "5px 40px 5px 12px",
      },
    },
    
    "@media (max-width:371px)": {
      "& .MuiTypography-root": {
        fontSize: 11,
      },
    },
  },
  link: {
    outline: "none",
    background: "none",
    border: "none",
    padding: 0,
    cursor: "pointer",
    marginRight: 5,
    "&:last-child": {
      marginRight: 0,
    },
  },
}))

export default function MenuPoint({ name, link, active }) {
  const classes = useStyles({ active })

  return (
    <button
      aria-label="Menu Point"
      className={classes.link}
      onClick={() =>
        elementScrollIntoView(document.querySelector(`#${link}`), {
          behavior: "smooth",
        })
      }
    >
      <div className={classes.root}>
        <Typography variant="subtitle2">{name}</Typography>
      </div>
    </button>
  )
}
