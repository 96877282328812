import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"
import Title from "./title"
import LinkMenu from "./link"
import SocialLink from "./social"
import ContactLink from "./contact"
import LogoEvil from "../../../static/svg/logo_evilunion.svg"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    maxWidth: theme.palette.width.limit,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  wrapperEvil: {
    maxWidth: theme.palette.width.limit,
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "auto",
  },
  container: {
    padding: 100,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:1024px)": {
      padding: "6.25vw",
    },
  },
  contacts_text: {
    ...theme.typography.body1,

    fontSize: 14,
    "@media (max-width:1024px)": {
      fontSize: "0.88vw",
    },
    "@media (max-width:767px)": {
      fontSize: 12,
    },
    "@media (max-width:344px)": {
      fontSize: 11,
    },

    "& *": {
      margin: 0,
    },

    "& a": {
      display: "block",
      color: "inherit",
      textDecoration: "none",
    },
  },
  social: {
    display: "flex",
  },
  evilUnion: {
    "& a": {
      textDecoration: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "@media (max-width:767px)": {
        alignItems: "flex-start",
      },
    },
    "& p": {
      width: "fit-content",
      borderBottom: "2px solid rgba(255,255,255,.5)",
      marginBottom: 6,

      fontSize: "0.83vw",
      "@media (min-width:1440px)": {
        fontSize: 12,
      },
      "@media (max-width:767px)": {
        fontSize: "2.46vw",
      },
    },
  },
  logoEvil: {
    height: "auto",

    width: "10.27vw",
    "@media (min-width:1440px)": {
      width: 148,
    },
    "@media (max-width:767px)": {
      width: "30.39vw",
    },
  },
  containerEvil: {
    padding: "0px 100px 100px 100px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:1024px)": {
      padding: "6.25vw",
    },
  },
  disclaimer: {
    lineHeight: 1.5,
    opacity: 0.6,

    width: "73.75vw",
    fontSize: "0.83vw",
    "@media (min-width:1440px)": {
      width: 1062,
      fontSize: 12,
    },
    "@media (max-width:767px)": {
      marginTop: "6.16vw",
      width: "100%",
      fontSize: "2.46vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
}))

export default function Footer() {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query Footer {
      allPrismicHeader {
        edges {
          node {
            data {
              logo {
                localFile {
                  publicURL
                }
              }
              menu_point_1 {
                text
              }
              menu_point_2 {
                text
              }
              menu_point_3 {
                text
              }
              menu_point_4 {
                text
              }
            }
          }
        }
      }
      allPrismicFooter {
        edges {
          node {
            data {
              footer_point_1 {
                text
              }
              footer_point_3 {
                text
              }
              footer_point_2 {
                text
              }
              footer_point_4 {
                text
              }
              disclaimer_text {
                html
                text
              }
              contacts {
                html
                raw
                text
              }
            }
          }
        }
      }
      allPrismicFooterBody1SocialMedia {
        edges {
          node {
            primary {
              svg {
                localFile {
                  publicURL
                }
                alt
              }
              social_link {
                url
              }
            }
          }
        }
      }
      allPrismicFooterBody2Document {
        edges {
          node {
            primary {
              document_link {
                url
              }
              document_name {
                text
              }
            }
          }
        }
      }
      allPrismicFooterBodySocialMedia {
        edges {
          node {
            primary {
              contact_name {
                text
              }
              link {
                url
              }
            }
          }
        }
      }
    }
  `)

  const contact = data.allPrismicFooterBodySocialMedia.edges
  const social = data.allPrismicFooterBody1SocialMedia.edges
  const documents = data.allPrismicFooterBody2Document.edges
  const disclaimer = data.allPrismicFooter.edges[0].node.data.disclaimer_text
  const contacts = data.allPrismicFooter.edges[0].node.data.contacts

  return (
    <footer className={classes.footer}>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div>
              <Title name={"menu"} />
              <nav>
                <LinkMenu
                  name={
                    data.allPrismicHeader.edges[0].node.data.menu_point_1.text
                  }
                  link={"advantages"}
                />
                <LinkMenu
                  name={
                    data.allPrismicHeader.edges[0].node.data.menu_point_2.text
                  }
                  link={"modules"}
                />
                <LinkMenu
                  name={
                    data.allPrismicHeader.edges[0].node.data.menu_point_3.text
                  }
                  link={"support"}
                />
                <LinkMenu
                  name={
                    data.allPrismicHeader.edges[0].node.data.menu_point_4.text
                  }
                  link={"pre_order"}
                />
              </nav>
            </div>
            <div>
              <Title name={"contacts"} />
              <nav>
                <div
                  className={classes.contacts_text}
                  dangerouslySetInnerHTML={{
                    __html: contacts.html.includes("<pre>")
                      ? contacts.text
                      : contacts.html,
                  }}
                />

                {contact.map((link, i) => (
                  <ContactLink
                    key={"socials1" + i}
                    name={link.node.primary.contact_name.text}
                    link={link.node.primary.link.url}
                  />
                ))}
              </nav>
            </div>
            <div>
              <Title name={"social media"} />
              <nav className={classes.social}>
                {social.map((link, i) => (
                  <SocialLink
                    key={"socials2" + i}
                    social={link.node.primary.svg.localFile.publicURL}
                    alt={link.node.primary.svg.alt}
                    link={link.node.primary.social_link.url}
                  />
                ))}
              </nav>
            </div>
            <div>
              <Title name={"documents"} />
              <nav>
                {documents.map((link, i) => (
                  <ContactLink
                    key={"socials3" + i}
                    name={link.node.primary.document_name.text}
                    link={link.node.primary.document_link.url}
                  />
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.wrapperEvil}>
        <div className={classes.containerEvil}>
          <Typography className={classes.disclaimer} component="div">
            <div
              dangerouslySetInnerHTML={{
                __html: disclaimer.html.includes("<pre>")
                  ? disclaimer.text
                  : disclaimer.html,
              }}
            />
          </Typography>

          <div className={classes.evilUnion}>
            <a
              href="https://evilunion.com/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography>Designed & Developed by</Typography>
              <LogoEvil className={classes.logoEvil} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
