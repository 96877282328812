import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"
import { elementScrollIntoView } from "seamless-scroll-polyfill"

import MenuPoint from "./menuPoint"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    height: 100,
    background:
      "linear-gradient(180deg, rgba(13, 13, 13, 0.9) 50%, rgba(13, 13, 13, 0) 100%)",
    zIndex: 10000,
  },
  wrapper: {
    maxWidth: theme.palette.width.limit,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  container: {
    padding: "0px 30px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 164,
    "@media (max-width:1024px)": {
      width: "12vw",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkToCes: {
    cursor: "pointer",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    marginLeft: "2.29vw",
    "@media (min-width:1440px)": {
      marginLeft: 33,
    },
  },
  linkToCes__text: {
    fontSize: "0.97vw",
    "@media (min-width:1440px)": {
      fontSize: 14,
    },

    "& *": {
      margin: 0,
    },
  },
  linkToCes__logo: {
    marginTop: "0.27vw",
    width: "4.86vw",
    height: "2.77vw",
    "@media (min-width:1440px)": {
      marginTop: 4,
      width: 70,
      height: 40,
    },
  },
}))

export default function Header() {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query Header {
      allPrismicHeader {
        edges {
          node {
            data {
              logo {
                localFile {
                  publicURL
                }
                alt
              }
              menu_point_1 {
                text
              }
              menu_point_2 {
                text
              }
              menu_point_3 {
                text
              }
              menu_point_4 {
                text
              }
              menu_point_5 {
                text
              }
            }
          }
        }
      }
      prismicLandingContent {
        data {
          ces_header_signature {
            html
          }
          ces_icon {
            alt
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <header className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.logo}>
            <img
              src={`${data.allPrismicHeader.edges[0].node.data.logo.localFile.publicURL}`}
              alt={data.allPrismicHeader.edges[0].node.data.logo.alt}
              width="164"
              height="55"
            />
          </div>

          <nav className={classes.menu}>
            <MenuPoint
              name={data.allPrismicHeader.edges[0].node.data.menu_point_1.text}
              link={"advantages"}
            />
            <MenuPoint
              name={data.allPrismicHeader.edges[0].node.data.menu_point_2.text}
              link={"modules"}
            />
            <MenuPoint
              name={data.allPrismicHeader.edges[0].node.data.menu_point_3.text}
              link={"support"}
            />
            {/* <MenuPoint
              name={data.allPrismicHeader.edges[0].node.data.menu_point_4.text}
              link={"contact"}
            /> */}
            <MenuPoint
              name={data.allPrismicHeader.edges[0].node.data.menu_point_5.text}
              link={"pre_order"}
              active
            />

            <divr
              role="button"
              onClick={() =>
                window.open("https://gizmodo.com/ditch-your-tangle-of-cables-and-chargers-for-the-lego-l-1848295030", "_blank")
              }
              className={classes.linkToCes}
            >
              <Typography className={classes.linkToCes__text} component="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicLandingContent.data.ces_header_signature.html,
                  }}
                />
              </Typography>

              <img
                src={
                  data.prismicLandingContent.data.ces_icon.localFile?.publicURL
                }
                alt={data.prismicLandingContent.data.ces_icon.alt ?? "icon"}
                className={classes.linkToCes__logo}
                width={70}
                height={40}
                style={{ objectFit: "contain" }}
              />
            </divr>
          </nav>
        </div>
      </div>
    </header>
  )
}
