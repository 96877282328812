import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: 'fit-content',
        padding: '5px 30px 5px 12px',
        borderRadius: 1000,
        boxShadow: 'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.5)',
        marginBottom: 30,
        '@media (min-width:768px) and (max-width:1024px)': {
            padding: '0.31vw 1.88vw 0.31vw 0.75vw',
            marginBottom: '1.88vw',
            '& .MuiTypography-root': {
                fontSize: '1.06vw',
            },
        },
    },
    title: {
        color: "#fff",
        fontSize: 14,
        fontFamily: 'Krona One',
        '@media (min-width:768px) and (max-width:1024px)': {
                fontSize: '1.06vw',
        },
    },
}))

export default function Title({ name }) {

    const classes = useStyles()

    return (
        <div className={`${classes.root} addition`}>
            <Typography className={classes.title}>
                {name}
            </Typography>
        </div>
    )
}