import React from 'react'
import './layout.css'
import Header from './header'
import MobileHeader from './header/mobile'
import Footer from './footer'
import MobileFooter from './footer/mobile'

export default function Layout({ children }) {

    const IsMobile = typeof window !== 'undefined' && window.matchMedia("(max-width: 767px)").matches

    const header = (IsMobile) => {
        switch(IsMobile) {
            case true:
                return <MobileHeader />
            
            default:
                return <Header />
        }
    }

    const footer = (IsMobile) => {
        switch(IsMobile) {
            case true:
                return <MobileFooter />
            
            default:
                return <Footer />
        }
    }

    return (
        <div style={{ overflow: 'hidden', }}>
            {header(IsMobile)}
            {children}
            {footer(IsMobile)}
        </div>
    )
}