import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"
import Title from "../title"
import LinkMenu from "../link"
import SocialLink from "../social"
import ContactLink from "../contact"
import LogoEvil from "../../../../static/svg/logo_evilunion.svg"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "50px 15px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    "&:nth-child(2)": {
      marginTop: 60,
      "@media (max-width:344px)": {
        marginTop: 40,
      },
    },
    "& .footer-container-menu": {
      width: "44vw",
    },
  },
  contacts_text: {
    ...theme.typography.body1,

    fontSize: 14,
    "@media (max-width:1024px)": {
      fontSize: "0.88vw",
    },
    "@media (max-width:767px)": {
      fontSize: 12,
    },
    "@media (max-width:344px)": {
      fontSize: 11,
    },

    "& *": {
      margin: 0,
    },

    "& a": {
      display: "block",
      color: "inherit",
      textDecoration: "none",
    },
  },
  social: {
    display: "flex",
  },
  margin: {},
  evilUnion: {
    marginTop: 80,
    "& a": {
      textDecoration: "none",
    },
    display: "flex",
    flexDirection: "column",
    "& a > p": {
      width: "fit-content",
      borderBottom: "2px solid rgba(255,255,255,.5)",
      marginBottom: 6,

      "@media (max-width:767px)": {
        fontSize: "2.46vw",
      },
    },
  },
  logoEvil: {
    width: "30.39vw",
    height: "auto",
  },

  disclaimer: {
    lineHeight: 1.5,
    opacity: 0.6,

    width: "73.75vw",
    fontSize: "0.83vw",
    "@media (min-width:1440px)": {
      width: 1062,
      fontSize: 12,
    },
    "@media (max-width:767px)": {
      marginTop: "6.16vw",
      width: "100%",
      fontSize: "2.46vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
}))

export default function MobileFooter() {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query MobileFooter {
      allPrismicHeader {
        edges {
          node {
            data {
              logo {
                url
              }
              menu_point_1 {
                text
              }
              menu_point_2 {
                text
              }
              menu_point_3 {
                text
              }
              menu_point_4 {
                text
              }
            }
          }
        }
      }
      allPrismicFooter {
        edges {
          node {
            data {
              footer_point_1 {
                text
              }
              footer_point_3 {
                text
              }
              footer_point_2 {
                text
              }
              footer_point_4 {
                text
              }
              disclaimer_text {
                html
                text
              }
              contacts {
                html
                raw
                text
              }
            }
          }
        }
      }
      allPrismicFooterBody1SocialMedia {
        edges {
          node {
            primary {
              svg {
                url
              }
              social_link {
                url
              }
            }
          }
        }
      }
      allPrismicFooterBody2Document {
        edges {
          node {
            primary {
              document_link {
                url
              }
              document_name {
                text
              }
            }
          }
        }
      }
      allPrismicFooterBodySocialMedia {
        edges {
          node {
            primary {
              contact_name {
                text
              }
              link {
                url
              }
            }
          }
        }
      }
    }
  `)

  const contact = data.allPrismicFooterBodySocialMedia.edges
  const social = data.allPrismicFooterBody1SocialMedia.edges
  const documents = data.allPrismicFooterBody2Document.edges
  const disclaimer = data.allPrismicFooter.edges[0].node.data.disclaimer_text
  const contacts = data.allPrismicFooter.edges[0].node.data.contacts

  return (
    <footer className={classes.root}>
      <div className={classes.container}>
        <div className="footer-container-menu">
          <Title name={"menu"} />
          <nav>
            <LinkMenu
              name={data.allPrismicHeader.edges[0].node.data.menu_point_1.text}
              link={"advantages"}
            />
            <LinkMenu
              name={data.allPrismicHeader.edges[0].node.data.menu_point_2.text}
              link={"modules"}
            />
            <LinkMenu
              name={data.allPrismicHeader.edges[0].node.data.menu_point_3.text}
              link={"support"}
            />
            <LinkMenu
              name={data.allPrismicHeader.edges[0].node.data.menu_point_4.text}
              link={"pre_order"}
            />
          </nav>
        </div>
        <div className="footer-container-menu">
          <Title name={"contacts"} />
          <nav>
            <div
              className={classes.contacts_text}
              dangerouslySetInnerHTML={{
                __html: contacts.html.includes("<pre>")
                  ? contacts.text
                  : contacts.html,
              }}
            />

            {contact.map((link, i) => (
              <ContactLink
                key={"socials4" + i}
                name={link.node.primary.contact_name.text}
                link={link.node.primary.link.url}
              />
            ))}
          </nav>
        </div>
      </div>
      <div className={classes.container}>
        <div className="footer-container-menu">
          <Title name={"social media"} />
          <nav className={classes.social}>
            {social.map((link, i) => (
              <SocialLink
                key={"socials5" + i}
                social={link.node.primary.svg.url}
                link={link.node.primary.social_link.url}
              />
            ))}
          </nav>
        </div>
        <div className="footer-container-menu">
          <Title name={"documents"} />
          <nav>
            {documents.map((link, i) => (
              <ContactLink
                key={"socials6" + i}
                name={link.node.primary.document_name.text}
                link={link.node.primary.document_link.url}
              />
            ))}
          </nav>
        </div>
      </div>
      <div className={classes.evilUnion}>
        <a
          href="https://evilunion.com/en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography>Designed & Developed by</Typography>
          <LogoEvil className={classes.logoEvil} />
        </a>

        <Typography className={classes.disclaimer} component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: disclaimer.html.includes("<pre>")
                ? disclaimer.text
                : disclaimer.html,
            }}
          />
        </Typography>
      </div>
    </footer>
  )
}
