import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Modal, Slide } from '@material-ui/core'
import Menu from '../../../../static/svg/menu.svg'
import ModalMenu from './modalMenu'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        width: 'calc(100vw - 30px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 15px',
        height: 80,
        background: 'linear-gradient(180deg, rgba(13, 13, 13, 0.9) 50%, rgba(13, 13, 13, 0) 100%)',
        zIndex: 1200,
    },
    logo: {
        width: 116,
    },
    menu: {
        width: 30,
    },
    modal: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection:"row",
    },
    paper: {
        background: 'rgb(0,0,0,0.3)',
        backdropFilter: 'blur(40px)',
        borderLeft: '1px solid rgb(255,255,255,0.15)',
        outline: 'none',
        width: '85%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default function MobileHeader() {

    const classes = useStyles()

    const data = useStaticQuery(graphql`
        query MobileHeader {
            allPrismicHeader {
                    edges {
                    node {
                        data {
                            logo {
                                localFile {
                                  publicURL
                                }
                                alt
                            }
                            menu_point_1 {
                                text
                            }
                            menu_point_2 {
                                text
                            }
                            menu_point_3 {
                                text
                            }
                            menu_point_4 {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    const [mobileMenu, setMobileMenu] = React.useState(false)

    return (
        <header className={classes.root}>
            <div className={classes.logo}>
                <img src={`${data.allPrismicHeader.edges[0].node.data.logo.localFile.publicURL}`} style={{ display: 'block', }}
                    alt={data.allPrismicHeader.edges[0].node.data.logo.alt}
                    width="116" height="39" />
            </div>
            <div className={classes.menu}>
                <Menu onClick={() => setMobileMenu(true)} />
            </div>

            <Modal
                aria-labelledby="update-modal-title"
                aria-describedby="update-modal-description"
                open={mobileMenu}
                disableAutoFocus={true}
                onClose={() => setMobileMenu(false)}
                className={classes.modal}>
                <Slide direction="left" in={mobileMenu} mountOnEnter unmountOnExit>
                    <div className={classes.paper}>
                        <ModalMenu data={data} setMobileMenu={setMobileMenu} />
                    </div>
                </Slide>
            </Modal>
        </header>
    )
}