import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Divider } from '@material-ui/core'
import MenuPoint from '../../menuPoint'
import SocialLink from '../../../footer/social'
import ContactLink from '../../../footer/contact'

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        padding: '8vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    logo: {
        width: '60%',
        marginTop: '8vw',
    },
    social: {
        display: 'flex',
        marginBottom: '8vw',
    },
    divider: {
        width: '100%',
        background: 'white',
        opacity: .6,
        marginTop: '8vw',
        marginBottom: '8vw',
    },
    margin: {
        marginTop: '6vw',
    },
    margin2: {
        marginBottom: '15px !important',
        '&:last-child': {
            marginBottom: '0px !important',
        },
    },
}))

export default function MobileMenu({ data, setMobileMenu }) {

    const classes = useStyle()

    const subData = useStaticQuery(graphql`
        query Modal {
            allPrismicFooterBody1SocialMedia {
                edges {
                    node {
                        primary {
                            svg {
                                localFile {
                                    publicURL
                                }
                                alt
                            }
                            social_link {
                                url
                            }
                        }
                    }
                }
            }
            allPrismicFooterBodySocialMedia {
                edges {
                    node {
                        primary {
                            contact_name {
                                text
                            }
                            link {
                                url
                            }
                        }
                    }
                }
            }
        }
    `)

    const contact = subData.allPrismicFooterBodySocialMedia.edges
    const social = subData.allPrismicFooterBody1SocialMedia.edges

    return (
        <div className={classes.root}>
            <nav role="presentation" className={classes.logo} onClick={() => setMobileMenu(false)}>
                <img src={`${data.allPrismicHeader.edges[0].node.data.logo.localFile.publicURL}`} alt="PWR BOARD" style={{ display: 'block', }} />
            </nav>
            <div>
                <nav role="presentation" className={classes.menu} onClick={() => setMobileMenu(false)}>
                    <MenuPoint name={data.allPrismicHeader.edges[0].node.data.menu_point_1.text} link={'advantages'} />
                    <div className={classes.margin2} />
                    <MenuPoint name={data.allPrismicHeader.edges[0].node.data.menu_point_2.text} link={'modules'} />
                    <div className={classes.margin2} />
                    <MenuPoint name={data.allPrismicHeader.edges[0].node.data.menu_point_3.text} link={'support'} />
                    <div className={classes.margin2} />
                    <MenuPoint name={data.allPrismicHeader.edges[0].node.data.menu_point_4.text} link={'pre_order'} />
                </nav>
                <Divider orientation="horizontal" className={classes.divider} />
                <nav role="presentation" onClick={() => setMobileMenu(false)}>
                    {contact.map((link, i) => <ContactLink key={"socials7" + i} name={link.node.primary.contact_name.text} link={link.node.primary.link.url} />)}
                </nav>
            </div>
            <nav role="presentation" className={classes.social} onClick={() => setMobileMenu(false)}>
                {social.map((link, i) => <SocialLink key={"socials8" + i}social={link.node.primary.svg.localFile.publicURL} alt={link.node.primary.svg.alt} link={link.node.primary.social_link.url} />)}
            </nav>
        </div>
    )
}
